export enum HttpStatusEnum {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404
}

export enum HttpErrorMessageEnum {
  InvalidToken = 'INVALID_TOKEN',
  AccountNotFound = 'ACCOUNT_NOT_FOUND'
}
