<div class="dialog-frame max-w-[520px]">
  <div class="mat-dialog-body">
    <div mat-dialog-title>
      <h3 class="text-base mb-2.5">
        {{
          'home_device_verification'
            | translate: { step: step(), stepCount: stepCount() }
        }}
      </h3>
    </div>

    <mat-dialog-content>
      @if (step() === deviceVerificationStepperEnum.One) {
        @if (authStore.authUser()?.tfaType === verificationOptionEnum.Email) {
          <div
            class="text-base"
            [innerHTML]="
              'home_device_verification_sent_email'
                | translate: { email: authStore.authUser()?.email }
            "
          ></div>

          <mat-form-field class="w-full max-w-[310px]">
            <mat-label>{{
              'home_enter_code_placeholder' | translate
            }}</mat-label>
            <input
              #inputElement
              type="text"
              matInput
              [formControl]="verificationCodeControl"
              (keydown.enter)="onVerify()"
            />

            @if (this.isDocumentClicked()) {
              <mat-error>
                {{
                  verificationCodeControl.errors | errorMessage: errorMessage
                }}
              </mat-error>
            }
          </mat-form-field>

          <div class="text-sm">
            {{ 'home_no_received_code' | translate }}

            @if (resendCodeCounter$ | async; as seconds) {
              {{ 'home_resend' | translate }} 00:{{ seconds | number: '2.0-0' }}
            } @else {
              <button
                [disabled]="
                  authStore.resendCodeVerificationCallState() ===
                  callStateEnum.Loading
                "
                (click)="onResendCode(inputElement)"
                class="underline capitalize"
              >
                {{ 'home_resend' | translate }}
              </button>
            }
          </div>

          <div
            class="text-sm"
            [innerHTML]="
              'home_having_trouble' | translate: { url: contactPageUrl }
            "
          ></div>
        } @else if (
          authStore.authUser()?.tfaType === verificationOptionEnum.App
        ) {
          <div
            class="text-base"
            [innerHTML]="'home_app_desc_text' | translate"
          ></div>

          <mat-form-field class="w-full max-w-[310px]">
            <mat-label>{{
              'home_enter_code_placeholder' | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              [formControl]="verificationCodeControl"
              mask="A A A  A A A"
              [showMaskTyped]="true"
              [specialCharacters]="[' ', '_']"
              (keydown.enter)="onVerify()"
            />

            @if (this.isDocumentClicked()) {
              <mat-error>
                {{
                  verificationCodeControl.errors | errorMessage: errorMessage
                }}
              </mat-error>
            }
          </mat-form-field>

          <div
            class="text-sm"
            [innerHTML]="
              'home_having_trouble' | translate: { url: contactPageUrl }
            "
          ></div>
        }
      } @else if (step() === deviceVerificationStepperEnum.Two) {
        <div [innerHTML]="'home_do_you_wish' | translate"></div>
      }
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions>
    <button
      (click)="
        step() === deviceVerificationStepperEnum.Two
          ? onChangeDeviceStatus(deviceStatusEnum.VerifiedNotTrusted)
          : onCancel()
      "
      mat-raised-button
      class="btn-basic mr-1"
    >
      @if (step() === deviceVerificationStepperEnum.One) {
        {{ 'home_cancel' | translate }}
      } @else if (step() === deviceVerificationStepperEnum.Two) {
        {{ 'home_no_way' | translate }}
      }
    </button>

    <button
      (click)="
        step() === deviceVerificationStepperEnum.One
          ? onVerify()
          : onChangeDeviceStatus(deviceStatusEnum.Verified)
      "
      [disabled]="
        step() === deviceVerificationStepperEnum.One
          ? verificationCodeControl.invalid ||
            authStore.codeVerificationCallState() === callStateEnum.Loading
          : authStore.changeDeviceStatusCallState() === callStateEnum.Loading
      "
      mat-raised-button
      class="btn-primary"
      tabindex="0"
    >
      {{
        step() === deviceVerificationStepperEnum.One
          ? ('home_verify' | translate)
          : ('home_yes_sure' | translate)
      }}
    </button>
  </div>
</div>
