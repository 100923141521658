import { AuthUserModel, VerificationOptionEnum } from '@models/auth.model';

export class ContactInfoModel {
  contactName: string;
  emailAddress: string;
  phoneNumber: number;

  static toJson(json: ContactInfoModel): any {
    return {
      contact_name: json.contactName,
      email: json.emailAddress,
      phone_number: json.phoneNumber
    };
  }
}

export type ChangePasswordReqPayload = {
  oldPassword: string;
  newPassword: string;
};

export interface IDeviceVerificationData {
  userAccount: AuthUserModel;
  jwtToken: string;
}

export interface ITwoFAAuthenticationInfo {
  qrImageUrl: string;
  id: string;
}

export type TwoFaInfoReqPayload = {
  accountId: number;
  type: VerificationOptionEnum;
  jwtToken?: string;
  isLogin?: boolean;
};

export type Check2faVerificationCodeReqPayload = {
  accountId: number;
  verificationCode: string;
  tfaType: VerificationOptionEnum;
  secret?: string;
  jwt: string;
};

export enum TwoFaDeviceVerificationStepEnum {
  QR = 1,
  VerificationCode,
  AssociateDevice,
  Loading
}
