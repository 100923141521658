import { signalStoreFeature, withMethods, withState } from '@ngrx/signals';
import { inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ToastyEnum } from '@models/toasty.model';

export function withMessageToaster() {
  return signalStoreFeature(
    withState({}),
    withMethods(
      (
        _store,
        matSnackBar = inject(MatSnackBar),
        translateService = inject(TranslateService)
      ) => {
        const showToastyMessage = (defaultToastyType: ToastyEnum) => {
          return (
            message: string,
            configs?: {
              toastyPanelClass?: ToastyEnum;
              toastyAction?: string;
              toastyDuration?: number;
              toastyVerticalPosition?: MatSnackBarVerticalPosition;
              toastyHorizontalPosition?: MatSnackBarHorizontalPosition;
              translateMessage?: boolean;
              translateInterpolateParams?: Object;
            }
          ) => {
            const matSnackConfigs: MatSnackBarConfig = {
              panelClass: configs?.toastyPanelClass ?? defaultToastyType,
              horizontalPosition: configs?.toastyHorizontalPosition ?? 'center',
              verticalPosition: configs?.toastyVerticalPosition ?? 'top'
            };

            if (typeof configs?.toastyDuration !== 'undefined') {
              matSnackConfigs.duration = configs?.toastyDuration;
            }

            if (configs?.translateMessage) {
              message = translateService.instant(
                message,
                configs.translateInterpolateParams ?? {}
              );

              if (configs.toastyAction) {
                configs.toastyAction = translateService.instant(
                  configs.toastyAction
                );
              }
            }

            matSnackBar.open(message, configs?.toastyAction, matSnackConfigs);
          };
        };
        return {
          showErrorMessage: showToastyMessage(ToastyEnum.Error),
          showSuccessMessage: showToastyMessage(ToastyEnum.Success),
          showInfoMessage: showToastyMessage(ToastyEnum.Info),
          showWarningMessage: showToastyMessage(ToastyEnum.Warning)
        };
      }
    )
  );
}
