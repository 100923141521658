import { AuthService } from '@services/auth.service';
import { AccountService } from '@api/account.service';
import { SessionStorageService } from '@services/abstract-storage.service';
import { Observable, of } from 'rxjs';
import { AuthUserModel, AuthUserRoleEnum } from '@models/auth.model';
import { USER_ID_KEY } from '@utils/storage.utils';

export const getAuthUser = (
  authService: AuthService,
  accountService: AccountService,
  sessionStorageService: SessionStorageService
): Observable<AuthUserModel | null> => {
  authService.decodeUserAccount();
  const decodeUserAccount: AuthUserModel = authService.decodedUserAccount;

  if (!decodeUserAccount) {
    return of(null);
  }

  // Impersonated account
  if (decodeUserAccount.role === AuthUserRoleEnum.Customer) {
    return accountService.getAccount(decodeUserAccount.id);
  } else {
    // impersonated account id
    const userId = sessionStorageService.getItem<string>(USER_ID_KEY);
    return userId
      ? accountService.getAccountByWithPermissionChange(userId)
      : of(null);
  }
};
