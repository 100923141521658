<div class="dialog-frame w-[710px] down-lg:w-full">
  <div class="mat-dialog-body">
    @if (currentStep) {
      <div mat-dialog-title>
        <h3 class="text-base mb-4">
          @if (currentStep() !== twoFaDeviceVerificationEnum.Loading) {
            {{
              'home_device_verification'
                | translate: { step: currentStep(), stepCount: 3 }
            }}
          } @else {
            {{ 'home_device_verification_text' | translate }}
          }
        </h3>
      </div>
    }
    <mat-dialog-content>
      @switch (currentStep()) {
        @case (twoFaDeviceVerificationEnum.QR) {
          <div
            [innerHTML]="
              'settings_page_google_auth_dialog_verify_title' | translate
            "
          ></div>

          <div class="flex flex-col items-center down-md:flex-col-reverse">
            <div class="mt-5">
              <img
                width="200"
                height="200"
                [src]="authStore.tfaAccountInfo().qrImageUrl"
                alt="QR"
              />
            </div>

            <div
              class="flex flex-between-center gap-2 px-4 py-2 mt-5 rounded bg-light-grey"
            >
              <span class="word-break">{{
                authStore.tfaAccountInfo().id
              }}</span>

              <mat-icon
                class="cursor-pointer text-sort-grey !flex items-center !text-[22px] down-md:!w-[45px] down-md:!h-[45px]"
                [cdkCopyToClipboard]="authStore.tfaAccountInfo().id"
                (cdkCopyToClipboardCopied)="onShowCopyToasty()"
                >content_copy</mat-icon
              >
            </div>
          </div>

          <div
            class="mt-7 text-sm"
            [innerHTML]="
              'settings_page_google_auth_dialog_support'
                | translate: { url: contactPageUrl }
            "
          ></div>
        }
        @case (twoFaDeviceVerificationEnum.VerificationCode) {
          <div
            class="text-base"
            [innerHTML]="'home_app_desc_text' | translate"
          ></div>

          <mat-form-field class="w-full max-w-[310px] my-6">
            <input
              (keydown.enter)="onVerify()"
              [formControl]="verificationCodeControl"
              [showMaskTyped]="true"
              [specialCharacters]="[' ', '_']"
              matInput
              type="text"
              class="text-sort-grey"
              mask="A A A  A A A"
            />

            <mat-error>
              {{ verificationCodeControl.errors | errorMessage: errorMessage }}
            </mat-error>
          </mat-form-field>

          <div
            class="text-sm"
            [innerHTML]="
              'home_having_trouble' | translate: { url: contactPageUrl }
            "
          ></div>
        }
        @case (twoFaDeviceVerificationEnum.AssociateDevice) {
          {{ 'home_do_you_wish' | translate }}
        }
        @case (twoFaDeviceVerificationEnum.Loading) {
          <div class="w-full flex-center-center">
            <mat-spinner color="accent" diameter="30"></mat-spinner>
          </div>
        }
      }
    </mat-dialog-content>
  </div>

  <div mat-dialog-actions>
    @if (currentStep() === twoFaDeviceVerificationEnum.QR) {
      @if (!dialogData.isLogin) {
        <button mat-dialog-close mat-raised-button class="btn-basic">
          {{ 'home_cancel' | translate }}
        </button>
      }

      <button
        (click)="currentStep.set(twoFaDeviceVerificationEnum.VerificationCode)"
        mat-raised-button
        class="btn-primary"
      >
        {{ 'settings_page_google_auth_qr_next_btn' | translate }}
      </button>
    } @else if (
      currentStep() === twoFaDeviceVerificationEnum.VerificationCode
    ) {
      <button (click)="onBackOrCancel()" mat-raised-button class="btn-basic">
        {{
          dialogData.isLogin
            ? ('global_back' | translate)
            : ('home_cancel' | translate)
        }}
      </button>

      <button
        (click)="onVerify()"
        [disabled]="verificationCodeControl.errors"
        mat-raised-button
        class="btn-primary"
      >
        {{ 'home_verify' | translate }}
      </button>
    } @else if (currentStep() === twoFaDeviceVerificationEnum.AssociateDevice) {
      <button mat-dialog-close mat-raised-button class="btn-basic">
        {{ 'home_no_way' | translate }}
      </button>

      <button
        (click)="onAssociateDevice()"
        mat-raised-button
        class="btn-primary"
      >
        {{ 'home_yes_sure' | translate }}
      </button>
    }
  </div>
</div>
